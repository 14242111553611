<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../../assets/images/logo/logo.png"/>
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
        class="breadcrumb-area ptb--60 bg_image bg_dark"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">Kursplattform</h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                      item.text
                    }}
                  </router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="">
                <h2>Kursplattform</h2>
                <p class="mt-2">
                  Entwicklung einer Plattform zur Verwaltung und Anzeige von Deutschkursangeboten. <br>
                  Verwaltung der
                  angebotenen Deutschkurse über ein Dashboard für die Kursanbieter.
                </p>

                <div class="d-flex flex-wrap">
                  <div class="">
                    <div class="thumb mt--60 mb--120">
                      <img
                          src="../../assets/images/portfolio/deutschkurse-1.png"
                          alt="Portfolio Images"
                      />
                    </div>
                    <div class="thumb mb--120">
                      <img
                          src="../../assets/images/portfolio/deutschkurse-4.png"
                          alt="Portfolio Images"
                      />
                    </div>
                    <div class="thumb mb--120">
                      <img
                          src="../../assets/images/portfolio/deutschkurse-3.png"
                          alt="Portfolio Images"
                      />
                    </div>
                    <div class="thumb ">
                      <img
                          src="../../assets/images/portfolio/deutschkurse-2.png"
                          alt="Portfolio Images"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->

    <!-- Start Related Work  -->
    <!--<div class="portfolio-related-work pb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="section-title text-center">
              <span class="theme-color font--18 fontWeight600"
              >Weitere Projekte</span
              >
              <h2>Our More Projects</h2>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt--10">
          <v-col
              lg="6"
              md="6"
              cols="12"
              v-for="(project, i) in moreProjects"
              :key="i"
          >
            <div class="related-work text-center mt--30">
              <div class="thumb">
                <a href="/portfolio-details">
                  <img :src="project.src" alt="Portfolio-images"/>
                </a>
              </div>
              <div class="inner">
                <h4>
                  <a href="/portfolio-details">{{ project.title }}</a>
                </h4>
                <span class="category">{{ project.categorie }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    -->
    <!-- End Related Work  -->

    <Footer/>
  </div>
</template>

<script>
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import feather from "feather-icons";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      items: [
        {
          thumb: require("../../assets/images/portfolio/deutschkurse-1.png")
        },
      ],
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
          disabled: false,
        },
        {
          text: "Projekte",
          to: "/projects",
          disabled: false,
        },
        {
          text: "Kursplattform",
          to: "",
          disabled: true,
        },
      ],
      moreProjects: [
        /*{
          src: require("../../assets/images/portfolio/related-image-01.jpg"),
          title: "Digital Analysis",
          categorie: "Technique",
        },
        {
          src: require("../../assets/images/portfolio/related-image-02.jpg"),
          title: "Plan Management",
          categorie: "Planning",
        },*/
      ],
      index: null,
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
